import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store, {persist} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import {ParallaxProvider} from "react-scroll-parallax";
import {ThemeProvider} from "react-bootstrap";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={store}>
          <PersistGate loading={null} persistor={persist}>
              <BrowserRouter>
                  <ParallaxProvider>
                      <ThemeProvider
                          breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
                          minBreakpoint="xxs"
                      >
                          <App />
                      </ThemeProvider>
                  </ParallaxProvider>
              </BrowserRouter>
          </PersistGate>
      </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

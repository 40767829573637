import React, {useEffect, useState} from 'react';
import HomeFirst from "../HomeFirst/HomeFirst";
import Gravity from "../../components/Gravity/Gravity";
import {AnimatePresence} from "framer-motion";
import Speciality from "../Speciality/Speciality";


const FirstContent = ({changeStatus}) => {
    const [firstContent, setFirstContent] = useState(1)
    const [specialityInfo, setSpecialityInfo] = useState(null)
    useEffect(() => {
        window.scrollTo(0,0)
    },[firstContent])
    return (
        <AnimatePresence>
            {firstContent===1&&
                <HomeFirst setFirstContent={setFirstContent}/>}
            {firstContent===2&&
                <Gravity
                    changeStatus={changeStatus}
                    setSpecialityInfo={setSpecialityInfo}
                    setFirstContent={setFirstContent}
                />}
            {firstContent===3&&
                <Speciality
                    data={specialityInfo}
                    changeStatus={changeStatus}
                />}
        </AnimatePresence>
    );
};

export default FirstContent;

import React from "react";
import {motion} from "framer-motion";
import style from './Gravity.module.sass';
import girlStick from '../../images/girlStick.png'
import GravityImg1 from '../../images/GravityImg1.png';
import GravityImg2 from '../../images/GravityImg2.png';
import GravityImg3 from '../../images/GravityImg3.png';
import GravityImgArrow1 from '../../images/GravityImgArrow1.png';
import GravityImgArrow2 from '../../images/GravityImgArrow2.png';
import GravityImgArrow3 from '../../images/GravityImgArrow3.png';
import data from "../../data/data";


const Gravity = ({setSpecialityInfo, setFirstContent, changeStatus}) => {
    const {specialPage} = data;
    const listVariants = {
        visible: i =>({
            x: 0,
            opacity: 1,
            rotate: 0,
            transition: {
                delay: i*0.5,
            }
        }),
        hidden: {
            x: -200,
            rotate: 180,
            opacity: 0
        }
    }
    //отправка запроса на изменение статуса о первоночальном контенте

    return (
        <motion.div
            initial={{x: 1000, opacity: 0.3}}
            animate={{x: 0, opacity: 1}}
            exit={{x: -2000, opacity: 0.3}}
            transition={{duration: 0.2}}
            key={"HomePage"}
            className={style.main}>
            <div className={style.mainWhiteBox}>
                <div className={style.mainWhiteBoxLeft}>
                    <div className={style.mainWhiteBoxLeftText}>
                        <h1>люди, которые могут быть вам полезны</h1>
                        <p>выберите специалиста по нужному вам направлению, и он сможет помочь решить поставленную задачу</p>
                    </div>
                    <div className={style.mainWhiteBoxLeftBtnBox}>
                        {specialPage.map((item, index)=>{
                            return (
                                <motion.div
                                    onClick={()=> {
                                        setSpecialityInfo(specialPage[index]);
                                        setFirstContent(3);
                                    }}
                                    variants={listVariants}
                                    initial={'hidden'}
                                    animate={'visible'}
                                    custom={index}
                                    key={index}
                                    className={style.mainWhiteBoxLeftBtnBoxElement}
                                >
                                    {item.context}
                                </motion.div>
                            )
                        })}
                    </div>
                </div>
                <div className={style.mainWhiteBoxGirl}>
                    <img src={girlStick} alt=""/>
                </div>
            </div>
            <div className={style.mainBlackBox}>
                <div className={style.mainBlackBoxContainer}>
                    <h1>мы создали эту платформу, чтобы вы не тратили время на поиски помощников на просторах всего интернета.</h1>
                    <div className={style.mainBlackBoxContainerCenter}>
                        <div className={style.mainBlackBoxContainerCenterLeft}>
                            <div className={style.mainBlackBoxContainerCenterLeftImg}>
                                <div className={style.mainBlackBoxContainerCenterLeftImgArrow}>
                                    <img src={GravityImgArrow1} alt=""/>
                                </div>
                                <div className={style.mainBlackBoxContainerCenterLeftImgBig}>
                                    <img src={GravityImg1} alt=""/>
                                </div>
                            </div>
                            <span>интуитивный и простой дизайн платформы</span>
                        </div>
                        <div className={style.mainBlackBoxContainerCenterRight}>
                            <span>профессиональная обратная связь 24/7</span>
                            <div className={style.mainBlackBoxContainerCenterRightImg}>
                                <div className={style.mainBlackBoxContainerCenterRightImgArrow}>
                                    <img src={GravityImgArrow2} alt=""/>
                                </div>
                                <div className={style.mainBlackBoxContainerCenterRightImgBig}>
                                    <img src={GravityImg2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.mainBlackBoxContainerFooter}>
                        <div className={style.mainBlackBoxContainerFooterImg}>
                            <div className={style.mainBlackBoxContainerFooterImgBig}>
                                <img src={GravityImg3} alt=""/>
                            </div>
                            <div className={style.mainBlackBoxContainerFooterImgArrow}>
                                <img src={GravityImgArrow3} alt=""/>
                            </div>
                        </div>
                        <span>экспертная поддержка юристов, бухгалтеров и IT специалистов</span>
                    </div>
                    <button
                        onClick={changeStatus}
                        className={style.mainBlackBoxContainerButton}>Найти помощь</button>
                </div>
            </div>
        </motion.div>
    );
};
export default Gravity;


import {useEffect, useRef, useState} from 'react'
import messageSound from '../sound/zvuk-uvedomleniya-dlya-messendjera.mp3'
// получаем класс IO
import {io} from 'socket.io-client'
import {useDispatch, useSelector} from "react-redux";
import {
    addOnlineAllUser,
    addOnlineUser,
    changeSocketStatus,
    deleteOnlineUser
} from "../redux/reducers/Slices/usersSlice";
import {
    addMessageChat,
    updateInfoUser,
    updateStatusMessage
} from "../redux/reducers/Slices/chatsSlice";
import useSound from "use-sound";
// хук принимает название комнаты
export const useSocket = () => {
    const [play] = useSound(messageSound)
    const dispatch = useDispatch();
    const {user} =useSelector(state=>state.auth)
    const [startSt, setStartSt]=useState(false)
    //useRef() используется не только для получения доступа к DOM-элементам,
    //но и для хранения любых мутирующих значений в течение всего жизненного цикла компонента
    const socketRef = useRef(null)
    useEffect(() => {
        const start = () => {
            if(user&&!startSt){
                setStartSt(true)
                const {token, id, secretIdChat, role} = user;
                // создаем экземпляр сокета, передаем ему адрес сервера
                // и записываем объект с ключом пользователя в строку запроса "рукопожатия"
                // socket.handshake.auth
                socketRef.current = io(process.env.REACT_APP_SOCKET_PORT, {
                    extraHeaders: {
                        "Authorization": `Bearer ${token}`
                    },
                    auth: {
                        tokenChat: secretIdChat
                    },
                }).on("connect",()=>{
                    console.log('connect')
                    dispatch(changeSocketStatus(true))
                })
                socketRef.current.on("disconnect", ()=>{
                    console.log('disconnect')
                    dispatch(changeSocketStatus(false))
                })
                // обрабатываем получение списка пользователей онлайн
                socketRef.current.emit('userOnline:get', role)
                socketRef.current.on('userOnline', (users) => {
                    // обновляем массив пользователей
                    dispatch(addOnlineAllUser(JSON.parse(users)))
                })
                // подписка на обновление списка юзеров онлайн при заходе нового пользователя
                socketRef.current.on('connectedAdmin', (users)=>dispatch(addOnlineUser(JSON.parse(users))))
                socketRef.current.on('connectedUser', (users)=>dispatch(addOnlineUser(JSON.parse(users))))
                //подписка на событие отключения юзера из противоположенной группы
                // событие зависит от роли
                switch(role){
                    case 'user':
                        socketRef.current.on('disconnectedAdmin', (users)=>dispatch(deleteOnlineUser(JSON.parse(users))));
                        break
                    case 'admin':
                        socketRef.current.on('disconnectedUser', (users)=>dispatch(deleteOnlineUser(JSON.parse(users))));
                        break
                    default:
                        return null
                }
                //подписка на новое сообщение
                socketRef.current.on('message:new-incoming',({message, companionInfo})=>{
                    dispatch(addMessageChat({message,companionInfo}))
                    if(message.userId!==id){
                        play()
                    }
                })
                //подписка на обновление сообщения - получение массива с прочитанными сообщениями
                //придет массив с id обновленных сообщений
                socketRef.current.on('message:update-incoming', (messages)=>{
                    dispatch(updateStatusMessage(messages))
                })

                //получение информации о пользователе которого не было в списке
                socketRef.current.on('user:info-incoming',(user)=>{
                    dispatch(updateInfoUser(user))
                })
                return () => {
                    // при размонтировании компонента выполняем отключение сокета
                    socketRef.current.disconnect()
                }
            }
        }
        start()
    //    добавил в зависимости play и user.id их не было
    }, [dispatch, play, user, startSt])

    // функция отправки сообщения
    // принимает объект с текстом сообщения и именем отправителя
    const sendMessage = ( chatId, content, idRecipient) => {
        // добавляем в объект id пользователя при отправке на сервер
        socketRef.current.emit('message:add', {
            chatId,
            idRecipient,
            content,
        })
    }
    //функция обновления сообщений
    const updateMessages = ( messagesId, idRecipient,idChat)=>{
        socketRef.current.emit('message:update', {
            messagesId,
            idRecipient,
            idChat
        })
    }
    const disconnectUser = ()=>{
        socketRef.current.disconnect()
        setStartSt(false)
    }
    // хук возвращает функции для отправки и обновления сообщений
    return { sendMessage, updateMessages, disconnectUser}
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuperAdmin_allUsers__6JHYH {\n  width: 83.3333333333vw;\n  margin: 2.6041666667vw auto;\n}\n.SuperAdmin_allUsers__6JHYH h1 {\n  font-family: Museo-900, sans-serif;\n  text-align: center;\n  font-size: 1.875vw;\n}\n.SuperAdmin_allUsersList__7ui9Q {\n  margin: 2.6041666667vw auto;\n  display: flex;\n  flex-direction: column;\n  gap: 1.3020833333vw;\n  width: 80%;\n}\n.SuperAdmin_allUsersListTitle__iQHp5 {\n  display: grid;\n  grid-template-columns: 10% 15% 15% 15% 10% 15% 20%;\n  font-family: Museo-900, sans-serif;\n  font-size: 0.9375vw;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/SuperAdmin.module.sass"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,2BAAA;AADF;AAEE;EACE,kCAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AACE;EACE,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;AACJ;AAAI;EACE,aAAA;EACA,kDAAA;EACA,kCAAA;EACA,mBAAA;AAEN","sourcesContent":["@import '../../sass/mixin'\n\n.allUsers\n  width: width1920(1600)\n  margin: width1920(50) auto\n  h1\n    font-family: Museo-900,sans-serif\n    text-align: center\n    font-size: width1920(36)\n  &List\n    margin: width1920(50) auto\n    display: flex\n    flex-direction: column\n    gap: width1920(25)\n    width: 80%\n    &Title\n      display: grid\n      grid-template-columns: 10% 15% 15% 15% 10% 15% 20%\n      font-family: Museo-900,sans-serif\n      font-size: width1920(18)\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allUsers": "SuperAdmin_allUsers__6JHYH",
	"allUsersList": "SuperAdmin_allUsersList__7ui9Q",
	"allUsersListTitle": "SuperAdmin_allUsersListTitle__iQHp5"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";

const ErrPage404 = () => {
    return (
        <div>
           Error 404 not found
        </div>
    );
};

export default ErrPage404;
import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import ChatsList from "../../components/Chat/ChatsList/ChatsList";
import MessageWindow from "../../components/Chat/MessagesWindow/MessageWindow";
import styles from './Chat.module.sass';
import {AnimatePresence, motion} from "framer-motion";

const Chat = ({sendMessage, updateMessages}) => {
    const {activeChat} = useSelector(state=>state.chats)
    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    return (
        <section className={styles.chat}>
            <h1 className={styles.chatTitle}>Сообщения</h1>
            <div className={styles.chatContainer}>
                <ChatsList/>
                <AnimatePresence>
                    {activeChat&&
                        <MessageWindow
                            sendMessage={sendMessage}
                            updateMessages={updateMessages}
                        />
                    }
                    {!activeChat&&
                        <motion.div
                            initial={{x: 300, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            exit={{x: -300, opacity: 0}}
                            className={styles.chatContainerEmpty}>
                            У вас нет активных диалогов
                        </motion.div>}
                </AnimatePresence>

            </div>
        </section>
    );
};

export default Chat;

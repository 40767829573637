import React, {useEffect} from 'react';
import style from './Home.module.sass'
import {useDispatch, useSelector} from "react-redux";
import FirstContent from "../../components/FirstContent/FirstContent";
import HomeUser from "../../components/HomeUser/HomeUser";
import {changeStatusContent} from "../../redux/reducers/Slices/authSlice";

const Home = () => {
    const {user} = useSelector(state=>state.auth);
    const dispatch = useDispatch();
    const { firstConnect } = user;
    const changeStatus = () =>{
        dispatch(changeStatusContent({id: user.id}))
    }
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "auto"
        })
    },[])
    return (
        <div className={style.home}>
            {!firstConnect&&<FirstContent changeStatus={changeStatus}/>}
            {firstConnect&&<HomeUser/>}
        </div>
    );
};
export default Home;

import React from 'react';

const Error = ({error}) => {
    return (
        <div>
           Ошибка
            {error}
        </div>
    );
};

export default Error;

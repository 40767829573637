import React from 'react';
import styles from './ChatsItem.module.sass';
import 'moment/locale/ru';
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import {changeActiveChats} from "../../../redux/reducers/Slices/chatsSlice";
import ChatItemMessages from "./ChatItemMessages/ChatItemMessages";
import ChatItemOnlineUser from "./ChatItemOnlineUser/ChatItemOnlineUser";
import ChatItemCountDate from "./ChatItemCountDate/ChatItemCountDate";

const ChatsItem = ({item}) => {

    const dispatch = useDispatch();
    const {activeChat} = useSelector(state=>state.chats)
    const {user} = useSelector(state=>state.auth)
    const specialityObj = {
        'lawyer': 'Юрист',
        'accounting':'Бухгалтер',
        'it':'IT специалист',
        'user':'Пользователь'
    }
    const {companion, messages} = item;
    const {userName, userId, userAvatars, speciality} = companion
    const newMessagesCount = messages.filter(i=>!i.status&&user.id!==i.userId).length
    return (
        <li
            onClick={()=>dispatch(changeActiveChats(companion.userId))}
            className={clsx(
                styles.item,
                activeChat===companion.userId&&styles.itemNew
            )}>
            <div className={styles.itemIcon}>
                <ChatItemOnlineUser userId={userId}/>
                <div className={styles.itemIconImg}>
                    {userAvatars&&<img src={`${process.env.REACT_APP_SERVER_URL}/user/avatars/${userAvatars}`} alt="icon"/>}
                </div>
            </div>
            <div className={styles.itemMessage}>
                <div className={styles.itemMessageTitle}>
                    {userName&&<h4>{userName}</h4>}
                    {speciality&&<span>({specialityObj[speciality]})</span>}
                </div>
                {messages.length>0&&<ChatItemMessages messages={messages}/>}
            </div>
            {messages.length!==0&&
                <ChatItemCountDate
                    newMessagesCount={newMessagesCount}
                    lastMessage={messages[messages.length-1].createdAt}
                />
            }
        </li>
    );
};

export default ChatsItem;

import React from 'react';
import {useSelector} from "react-redux";

const ChatItemOnlineUser = ({userId}) => {
    const {usersOnline} = useSelector(state=>state.users)
    if(usersOnline.find(i=>i.chatIdClient===userId))
    return (
        <span/>
    );
};

export default ChatItemOnlineUser;

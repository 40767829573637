import React from 'react';
import style from "../AllUsers.module.sass";
import {AnimatePresence} from "framer-motion";
import AllUserItem from "../AllUserItem/AllUserItem";
import {v4 as uuidv4} from "uuid";

const AllUserList = ({list}) => {
    return (
        <ul className={style.listUser}>
            <AnimatePresence>
                {list
                    // фильтрация только популярные
                    // .filter(item=>item.popular)
                    .map((item, index)=><AllUserItem item={item} index={index} key={uuidv4()}/>)}
            </AnimatePresence>
        </ul>
    );
};

export default AllUserList;

import React, {useState} from 'react';
import TextareaAutosize from "react-textarea-autosize";
import styles from './InputMessage.module.sass';
import sendMessageIco from '../../../icons/send.png'
const InputMessage = ({sendMessage, recipient, chatId}) => {

    //состояние зажатого shift
    const [shiftState, setShiftState] = useState(false);
    //состояние Textarea
    const [textAreaValue, setTextAreaValue] = useState('')
    const handleTextarea = (e)=>{
        setTextAreaValue(e.target.value)
    }
    //обработка переноса строки
    const handleOnKeyDown = (e) => {
        if(e.key==='Shift'&&!shiftState){
            setShiftState(true)
        }
        if(e.key==='Enter'&&!shiftState){
            e.preventDefault()
            handleSendMessage()
        }
    }
    const handleOnKeyUp = (e)=> {
        e.key==='Shift'&&setShiftState(false)
    }
    //отправка сообщения
    const handleSendMessage = () => {
        if(textAreaValue.length>0){
            sendMessage(chatId, textAreaValue, recipient)
            setTextAreaValue('')
        }
    }
    return (
        <div className={styles.inputMessage}>
            <span/>
            <label className={styles.inputMessageLabel}>
                <TextareaAutosize
                    placeholder={'Введите сообщение'}
                    minRows={1}
                    maxRows={3}
                    onKeyDown={handleOnKeyDown}
                    onKeyUp={handleOnKeyUp}
                    value={textAreaValue}
                    onChange={handleTextarea}
                />
                <div
                    onClick={handleSendMessage}
                    className={styles.inputMessageLabelSend}>
                    <img src={sendMessageIco} alt=""/>
                </div>
            </label>
        </div>
    );
};

export default InputMessage;

const data = {
    specialPage: [
        {
            title: 'айтишник',
            context:  'IT-специалист',
            possibilities: [
                {
                    title: 'размещать, обновлять, архивировать',
                    text: 'базы, и все это работает быстро!'
                },
                {
                    title: 'устанавливать и сопровождать',
                    text: 'системы сдачи/подготовки отчетности, помогать с ЭЦП'
                },
                {
                    title: 'организовать работу с документами',
                    text: 'их получением, редактированием и отправкой'
                },
                {
                    title: 'управлять программами',
                    text:'используемыми в работе'
                },
                {
                    title: 'увеличить скорость',
                    text: 'работы устройств, программ и сети'
                },
                {
                    title:  'следить за безопасностью',
                    text: 'и актуальностью систем защиты'
                },
                {
                    title:  'настраивать обмен',
                    text: 'и взаимодействие между рабочими средствами'
                }

            ]
        },
        {
            title: 'юрист',
            context: 'Юрист',
            possibilities: [
                {
                    title: 'сопровожу сделку',
                    text: 'купли продажи доли, акций, недвижимости'
                },
                {
                    title: 'проверю или составлю договор',
                    text: 'приказ, инструкцию, правила внутреннего распорядка'
                },
                {
                    title: 'зарегистрирую фирму или ИП',
                    text: 'внесу изменения, помогу с ликвидацией'
                },
                {
                    title: 'отвечу на запрос или вызов в налоговую',
                    text:'cоставлю ответ, жалобу, подготовлю и сопровожу на беседу'
                },
                {
                    title: 'помогу с взысканием задолженности',
                    text: 'cоставлю претензию, иск, возражения, жалобу'
                },
            ]
        },
        {
            title: 'бухгалтер',
            context: 'Бухгалтер',
            possibilities: [
                {
                    title: 'вести кадровый учет',
                    text: 'поболтать на любую тему'
                },
                {
                    title: 'обрабатывать первичную документацию',
                    text: 'приказ, инструкцию, правила внутреннего распорядка'
                },
                {
                    title: 'сдавать отчетность',
                    text: 'внесу изменения, помогу с ликвидацией'
                },
                {
                    title: 'подсказать варианты проводок',
                    text: 'cоставлю ответ, жалобу, подготовлю и сопровожу на беседу'
                },
                {
                    title: 'поболтать на любую тему',
                    text: 'задать любые вопросы'
                },
            ]
        }
    ]
}
export default data;

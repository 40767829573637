// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_home__Bnqtl {\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.sass"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF","sourcesContent":["@import '../../sass/mixin'\n\n.home\n  height: 100%\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "Home_home__Bnqtl"
};
export default ___CSS_LOADER_EXPORT___;

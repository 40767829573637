// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Preloader_preloader__edeFl {\n  position: absolute;\n  width: 7.8125vw;\n  height: auto;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.Preloader_preloader__edeFl img {\n  width: 100%;\n  height: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/Preloader/Preloader.module.sass"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,eAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":["@import '../../sass/mixin'\n.preloader\n  position: absolute\n  width: width1920(150)\n  height: auto\n  top: 50%\n  left: 50%\n  transform: translate(-50%, -50%)\n  display: flex\n  align-items: center\n  justify-content: center\n  img\n    width: 100%\n    height: auto"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preloader": "Preloader_preloader__edeFl"
};
export default ___CSS_LOADER_EXPORT___;

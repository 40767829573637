import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAllUsers} from "../../redux/reducers/Slices/usersSlice";
import SuperAdminUserItem from "./SuperAdminUserItem/SuperAdminUserItem";
import {v4 as uuidv4} from 'uuid';
import style from './SuperAdmin.module.sass';

const SuperAdmin = () => {
    const dispatch = useDispatch();
    const {allUsers, statusLoad} = useSelector(state=>state.users);
    useEffect(()=>{
        dispatch(getAllUsers())
    },[dispatch])
    if(statusLoad==='fulfilled'){
        return (
            <section className={style.allUsers}>
                <h1>Список пользователей</h1>
                <ul className={style.allUsersList}>
                    <li className={style.allUsersListTitle}>
                        <span>Иконка</span>
                        <span>Имя пользователя</span>
                        <span>Логин</span>
                        <span>Email</span>
                        <span>Роль</span>
                        <span>Специализация</span>
                    </li>
                    {allUsers.map((item)=>{
                            if(item.role!=='super_admin'){
                                return(
                                    <SuperAdminUserItem
                                        key={uuidv4()}
                                        user={item}
                                    />
                                )
                            } else {
                                return false
                            }
                        }
                    )}
                </ul>
            </section>
        );
    }

};

export default SuperAdmin;

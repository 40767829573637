import React from 'react';
import style from './Header.module.sass';
import logo from '../../images/logo.png';
import {useDispatch} from "react-redux";
import {logout} from "../../redux/reducers/Slices/authSlice";
import Menu from "../Menu/Menu";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import {Link, useNavigate} from "react-router-dom";

const Header = ({disconnect}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const logoutUser = () => {
        dispatch(logout())
        disconnect()
        navigate('/authorization')
    }

    return (
        <header className={style.header}>
            <div className={style.headerContainer}>
                <Link to={'/home'} className={style.headerContainerLogo}>
                    <img src={logo} alt=""/>
                </Link>
                <Menu position={''}/>
                <div className={style.headerContainerProfile}>
                    <ProfileHeader/>
                    <span
                        onClick={logoutUser}
                        className={style.headerContainerProfileLogout}
                    >
                    выйти
                </span>
                </div>
            </div>
        </header>
    );
};

export default Header;

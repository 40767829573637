import React from 'react';
import styles from "./MessagesListItem.module.sass";
import moment from 'moment';
import 'moment/locale/ru'
import {useSelector} from "react-redux";
import clsx from "clsx";

const MessagesListItem = ({message,refs}) => {
    const {user} = useSelector(state=>state.auth)
    const {content, status, createdAt, userId} = message
    return (
        <li
            ref={refs}
            className={clsx(!
                status&&user.id!==userId?'newMessage':'',
                    user.id===userId?styles.ItemMe:styles.Item,
                    !status&&user.id!==userId&&styles.NewMessage
        )}>
            <span className={user.id===userId?styles.ItemMeTime:styles.ItemTime}>{moment(createdAt).calendar()}</span>
            <span className={user.id===userId?styles.ItemMeMessage:styles.ItemMessage}>{content}</span>
        </li>
    );
};

export default MessagesListItem;

import React from 'react';
import style from './SuperAdminUserItem.module.sass'
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SuperAdminUserItem = ({user}) => {
    const navigate = useNavigate()
    const specialityList = {
        accounting: 'бухгалтер',
        lawyer: 'Юрист',
        it: 'IT специалист',
        user: 'Пользователь'
    }

    const {avatars, firstName, userName, email, role, speciality}=user

    return (
        <li className={style.item}>
            <div className={style.itemImage}>
                <img src={process.env.REACT_APP_SERVER_URL+`/user/avatars/${avatars}`} alt=""/>
            </div>
            <span>{firstName}</span>
            <span>{userName}</span>
            <span>{email}</span>
            <span>{role}</span>
            <span>{specialityList[speciality]}</span>
            <div className={style.itemBtn}>
                <Button onClick={()=> navigate(`setting-user/${user.id}`)} variant={"primary"}>Настроить</Button>
            </div>
        </li>
    );
};

export default SuperAdminUserItem;

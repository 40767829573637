import {combineReducers} from "@reduxjs/toolkit";
import authSlice from "./Slices/authSlice";
import formAuthorizationSlice from "./Slices/formAuthorizationSlice";
import usersSlice from "./Slices/usersSlice";
import chatsSlice from "./Slices/chatsSlice";

const rootReducer = combineReducers({
    formAuthorization: formAuthorizationSlice,
    auth: authSlice,
    users: usersSlice,
    chats: chatsSlice
})
export default rootReducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_modal__v9mA\\+, .Modal_modalActive__fsOOA {\n  z-index: 150;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  position: fixed;\n  background-color: rgba(34, 34, 34, 0.44);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition-property: opacity, transform;\n  transition-timing-function: linear;\n  transition-duration: 0.4s;\n}\n\n.Modal_modal__v9mA\\+ {\n  pointer-events: none;\n  opacity: 0;\n}\n\n.Modal_modalActive__fsOOA {\n  opacity: 1;\n}\n\n.Modal_content__-Rbby, .Modal_contentActive__f6XSm {\n  transition: 0.4s all;\n}\n\n.Modal_content__-Rbby {\n  transform: scale(0.3);\n}\n\n.Modal_contentActive__f6XSm {\n  transform: scale(1);\n}", "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.sass"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,wCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uCAAA;EACA,kCAAA;EACA,yBAAA;AAAF;;AAEA;EACE,oBAAA;EACA,UAAA;AACF;;AACA;EACE,UAAA;AAEF;;AAAA;EACE,oBAAA;AAGF;;AAFA;EACE,qBAAA;AAKF;;AAJA;EACE,mBAAA;AAOF","sourcesContent":["@import '../../sass/mixin'\n.modal, .modalActive\n  z-index: 150\n  top: 0\n  left: 0\n  height: 100vh\n  width: 100vw\n  position: fixed\n  background-color: rgba(34, 34, 34, 0.44)\n  display: flex\n  justify-content: center\n  align-items: center\n  transition-property: opacity, transform\n  transition-timing-function: linear\n  transition-duration: 0.4s\n\n.modal\n  pointer-events: none\n  opacity: 0\n\n.modalActive\n  opacity: 1\n\n.content, .contentActive\n  transition: 0.4s all\n.content\n  transform: scale(0.3)\n.contentActive\n  transform: scale(1)\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Modal_modal__v9mA+",
	"modalActive": "Modal_modalActive__fsOOA",
	"content": "Modal_content__-Rbby",
	"contentActive": "Modal_contentActive__f6XSm"
};
export default ___CSS_LOADER_EXPORT___;

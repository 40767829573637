import React, { useEffect, useMemo, useRef, useState} from 'react';
import styles from './MessagesList.module.sass';
import MessagesListItem from "../MessagesListItem/MessagesListItem";
import {v4 as uuidv4} from 'uuid';
import {useDispatch, useSelector} from "react-redux";

const MessagesList = ({recipient, messages, updateMessage}) => {
    const dispatch = useDispatch();
    //глобальные статусы
    const {user} = useSelector(state=>state.auth);
    const {activeChat, allUsersChats}=useSelector(state=>state.chats)
    const chatActive = allUsersChats.find(i=>i.companion.userId===activeChat)
    //Ref для элементов
    const listMessages = useRef(null);
    const refs = useMemo(() => messages.map(() => React.createRef()), [ messages]);

    //флаг для отправки обновления статуса
    const [msgDone, setMsgDone] = useState(false)
    //функция при scroll которая меняет состояние на отправку обновления сообщений и создает триггер только один раз
    const scrollHandler = () => {
        if(listMessages.current){
            if(listMessages.current.scrollHeight<=listMessages.current.scrollTop+listMessages.current.clientHeight+5){
                setMsgDone(true)
            }
        }
    }

    useEffect(()=>{
        setMsgDone(false)
        if(listMessages.current.scrollHeight<=listMessages.current.clientHeight){
            const newMessages = messages.filter(i=>!i.status&&i.userId!==user.id)
            const arrId = newMessages.map(i=>i.id)
            newMessages.length&&updateMessage(arrId, recipient, chatActive.chatId)
        }
        if(refs.length){
            const newMsg = refs.filter(i=>i.current.classList.contains('newMessage'))
            if(newMsg.length){
                listMessages.current.scrollTo({
                    top: newMsg[0].current.offsetTop - listMessages.current.clientHeight/2 - newMsg[0].current.clientHeight
                })
            } else {
                listMessages.current.scrollTo({
                    top: listMessages.current.scrollHeight
                })
            }
            if(newMsg.length===1){
                const newMessage = messages.filter(i=>!i.status&&i.userId!==user.id)
                const arrId = newMessage.map(i=>i.id)
                newMessage.length&&updateMessage(arrId, recipient, chatActive.chatId)
                // newMessage.length&&dispatch(updateStatusMessage({msgArr:arrId, idChat:chatActive}))
            }
        }
    }, [messages, recipient,updateMessage, user, refs, chatActive, dispatch])
    //подписка и перезапись на событие scroll
    useEffect(()=>{
        if(listMessages.current){
            listMessages.current.addEventListener('scroll', scrollHandler);
        }
    }, [activeChat])
    //отправка сообщения только один раз
    useEffect(()=>{
        //новые сообщения для контроля
        const newMessages = messages.filter(i=>!i.status&&i.userId!==user.id)
        const arrId = newMessages.map(i=>i.id)
        newMessages.length&&msgDone&&updateMessage(arrId, recipient, chatActive.chatId)
        // newMessages.length&&msgDone&&dispatch(updateStatusMessage({msgArr:arrId, idChat:chatActive}))
    },[msgDone, chatActive, dispatch, messages, recipient, updateMessage, user])

    return (
        <ul
            ref={listMessages}
            className={styles.MessagesList}
        >
            {messages.length!==0&&messages.map((item,index)=> {
                return <MessagesListItem refs={refs[index]} status={item.status} key={uuidv4()} message={item}/>
            })}
        </ul>
    );
};

export default MessagesList;

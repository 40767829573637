import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getUsers} from "../../../redux/reducers/Slices/usersSlice";
import Preloader from "../../Preloader/Preloader";
import Error from "../../Error/Error";
import style from './SearchSpecialistList.module.sass';
import {v4 as uuidv4} from 'uuid';
import SearchSpecialistItem from "../SearchSpecialistItem/SearchSpecialistItem";

const SearchSpecialistList = () => {
    const dispatch = useDispatch();
    const {allUsers, statusLoad, error} =useSelector(state => state.users);
    useEffect(()=>{
        dispatch(getUsers())
    }, [dispatch])
    return (
        <main className={style.searchSpecialistList}>
            <h1 className={style.searchSpecialistListTitle}>наши специалисты</h1>
            {statusLoad==='pending'&&<Preloader/>}
            {statusLoad==='fulfilled'&&<ul className={style.searchSpecialistListContainer}>
                {allUsers.map(item=><SearchSpecialistItem key={uuidv4()} user={item}/>)}
            </ul>}
            {statusLoad==='rejected'&&<Error error={error}/>}
        </main>
    );
};

export default SearchSpecialistList;
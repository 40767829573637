// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminHome_adminHome__TnTq7 {\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  font-size: 28px;\n}\n.AdminHome_adminHome__TnTq7 span {\n  font-weight: bold;\n}\n.AdminHome_adminHome__TnTq7 h2 {\n  font-size: 34px;\n  font-weight: bolder;\n}", "",{"version":3,"sources":["webpack://./src/pages/AdminHome/AdminHome.module.sass"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AADE;EACE,eAAA;EACA,mBAAA;AAGJ","sourcesContent":[".adminHome\n  margin: 0 auto\n  display: flex\n  flex-direction: column\n  gap: 20px\n  font-size: 28px\n  span\n    font-weight: bold\n  h2\n    font-size: 34px\n    font-weight: bolder\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminHome": "AdminHome_adminHome__TnTq7"
};
export default ___CSS_LOADER_EXPORT___;

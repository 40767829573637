import React, {useState, useCallback, useEffect} from 'react';
import {useSelector} from "react-redux";

const CountNewMessage = () => {
    const {allUsersChats} = useSelector(state=>state.chats)
    const {user} = useSelector(state=>state.auth)
    const [newMessages, setNewMessage] = useState(0);
    const getNewMassages = useCallback(()=>{
        let count = 0;
        if(allUsersChats){
            allUsersChats.forEach(item=>{
                count = item.messages.filter(item=>!item.status&&user.id!==item.userId).length
            })
        }
        setNewMessage(count)
    }, [allUsersChats, user])
    useEffect(()=>{
        getNewMassages()
    },[getNewMassages])
    if(newMessages>0){
        return (
            <span>
            {newMessages}
        </span>
        );
    }
};

export default CountNewMessage;

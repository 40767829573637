import React from 'react';
import style from './Menu.module.sass';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import CountNewMessage from "./CountNewMessage/CountNewMessage";

const Menu = ({position}) => {
    const {user} = useSelector(state=>state.auth)
    return (
        <ul
            className={style.menu}
            style={position==='vertical'?{flexDirection:'column'}:{flexDirection:'row'}}>
            <li>
                <NavLink to={'/home'} end>
                    Главная
                </NavLink>
            </li>
            {user.role!=='super_admin'&&
                <li>
                    <NavLink to={'/home/chat'}>
                        Чат
                    </NavLink>
                    <CountNewMessage/>
                </li>
            }
            {user.role!=='super_admin'&&
                <li>
                    <NavLink to={'/home/settings'}>
                        Настройки
                    </NavLink>
                </li>
            }
        </ul>
    );
};

export default Menu;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu_menu__CIecB {\n  display: flex;\n  gap: 1.0416666667vw;\n  font-family: Museo-500, sans-serif;\n  font-size: 1.25vw;\n  justify-content: center;\n  align-items: center;\n}\n.Menu_menu__CIecB li {\n  position: relative;\n  cursor: pointer;\n}\n.Menu_menu__CIecB li span {\n  font-size: 0.625vw;\n  position: absolute;\n  top: -40%;\n  right: -40%;\n  background-color: #794A91;\n  width: 1.0416666667vw;\n  height: 1.0416666667vw;\n  border-radius: 1.5625vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #FFFFFF;\n}\n.Menu_menu__CIecB li:hover {\n  transform: scale(1.05);\n  color: #AF05FF;\n}", "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.module.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,kCAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAEE;EACE,kBAAA;EACA,eAAA;AAAJ;AACI;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;EACA,qBAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AACN;AACE;EACE,sBAAA;EACA,cAAA;AACJ","sourcesContent":["@import '../../sass/mixin'\n\n.menu\n  display: flex\n  gap: width1920(20)\n  font-family: Museo-500, sans-serif\n  font-size: width1920(24)\n  justify-content: center\n  align-items: center\n  li\n    position: relative\n    cursor: pointer\n    span\n      font-size: width1920(12)\n      position: absolute\n      top: -40%\n      right: -40%\n      background-color: #794A91\n      width: width1920(20)\n      height: width1920(20)\n      border-radius: width1920(30)\n      display: flex\n      justify-content: center\n      align-items: center\n      color: #FFFFFF\n\n  li:hover\n    transform: scale(1.05)\n    color: #AF05FF\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "Menu_menu__CIecB"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import styles from './MessageWindow.module.sass';
import InputMessage from "../InputMessage/InputMessage";
import MessagesList from "../MessagesList/MessagesList";
import {motion} from "framer-motion";
import {useSelector} from "react-redux";

const MessageWindow = ({sendMessage, updateMessages}) => {
    const {allUsersChats, activeChat, tempUserChat} = useSelector(state=>state.chats)
    const activeChatInfo = allUsersChats.find(item=>item.companion.userId === activeChat)
    const speciality = {
        'lawyer': 'Юрист',
        'accounting':'Бухгалтер',
        'it':'IT специалист',
        'user':'Пользователь'
    }
    return (
        <motion.div
            initial={{x: 600, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            exit={{x: -600, opacity: 0}}
            className={styles.window}>
            {(tempUserChat||activeChatInfo)&&<div className={styles.windowHeader}>
                <div className={styles.windowHeaderIcon}>
                    <div className={styles.windowHeaderIconImg}>
                        <img src={`${process.env.REACT_APP_SERVER_URL}/user/avatars/${
                            activeChatInfo?
                                activeChatInfo.companion.userAvatars:
                                tempUserChat.companion.userAvatars
                        }`} alt="icon"/>
                    </div>
                </div>
                <div className={styles.windowHeaderTitle}>
                    <h4>{
                        activeChatInfo?
                            activeChatInfo.companion.userName:
                            tempUserChat.companion.userName
                    }</h4>
                    <span>({speciality[
                        activeChatInfo?
                            activeChatInfo.companion.speciality:
                            tempUserChat.companion.speciality
                        ]})</span>
                </div>
            </div>}
            {(tempUserChat||activeChatInfo)&&<MessagesList
                updateMessage={updateMessages}
                messages={
                    activeChatInfo?
                        activeChatInfo.messages:
                        tempUserChat.messages
                }
                recipient={
                    activeChatInfo?
                        activeChatInfo.companion.userId:
                        tempUserChat.companion.userId
                }
            />}
            {(tempUserChat||activeChatInfo)&&<InputMessage
                recipient={
                    activeChatInfo?
                        activeChatInfo.companion.userId:
                        tempUserChat.companion.userId
                }
                chatId={
                    activeChatInfo?
                        activeChatInfo.chatId:
                        tempUserChat.chatId
                }
                sendMessage={sendMessage}
            />}
        </motion.div>
    );
};

export default MessageWindow;

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const getUsers = createAsyncThunk(
    "users/getUsers",
    async(_,{rejectWithValue, getState})=>{
        const {auth} = getState();
        const {user} = auth;
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+'/user/all-users',{
                method: "GET",
                headers: {
                    'User-Role': user.role,
                    'UserID': user.id,
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const data = await response.json();
            if(!data.ok){
                return rejectWithValue(data)
            }
            return data;
        } catch (error){
            return rejectWithValue(error.message)
        }
    }
)

export const getAllUsers = createAsyncThunk(
    "users/getAllUsers",
    async (_,{rejectWithValue, getState})=>{
        const {auth} = getState();
        const {user} = auth;
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+'/user/all',{
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            if(!response.ok){
                return rejectWithValue({message: 'Ошибка на сервере'})
            }
            return await response.json().then()
        } catch (error){
            return rejectWithValue(error.message)
        }
    }
)

export const getInfoUsers = createAsyncThunk(
    "users/getInfoUsers",
    async ({id},{rejectWithValue, getState})=>{
        const {auth} = getState();
        const {user} = auth;
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+`/user/all/${id}`,{
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            if(!response.ok){
                return rejectWithValue({message: 'Ошибка на сервере'})
            }
            return await response.json().then()
        } catch (error){
            return rejectWithValue(error.message)
        }
    }
)



const usersSlice = createSlice({
    name: 'users',
    initialState: {
        allUsers: [],
        usersOnline: [],
        socketStatus: false,
        userInfo: null,
        statusLoad: 'idle',
        error: null
    },
    reducers: {
        addOnlineAllUser(state,action){
            state.usersOnline = action.payload
        },
        addOnlineUser(state, action){
            const check = state.usersOnline.find(item=> item.chatIdClient!==action.payload.chatIdClient
            )
            if(check) return
            state.usersOnline=[...state.usersOnline, action.payload]
        },
        deleteOnlineUser(state, action){
            state.usersOnline= state.usersOnline.filter(item=> item.chatIdClient !==action.payload.chatIdClient)
        },
        addChangeUser(state, action){
            const {name, value, } = action.payload;

            state.userInfo = {...state.userInfo, [name]: value}
        },
        changeSocketStatus(state, action){
            state.socketStatus = action.payload
        },
        addAllUsers(state, action){
            state.allUsers = action.payload
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getUsers.pending, (state)=>{
            state.error = null;
            state.statusLoad = 'pending';
        });
        builder.addCase(getUsers.fulfilled, (state, action)=>{
            state.allUsers = action.payload.data;
            state.statusLoad = 'fulfilled';
        });
        builder.addCase(getUsers.rejected, (state, action)=>{
            state.error = action.payload.message;
            state.statusLoad = 'rejected';
        });
        builder.addCase(getAllUsers.pending, (state)=>{
            state.error = null;
            state.statusLoad = 'pending';
        });
        builder.addCase(getAllUsers.fulfilled, (state, action)=>{
            state.allUsers = action.payload;
            state.statusLoad = 'fulfilled';
        });
        builder.addCase(getAllUsers.rejected, (state, action)=>{
            state.error = action.payload.message;
            state.statusLoad = 'rejected';
        });
        builder.addCase(getInfoUsers.pending, (state)=>{
            state.error = null;
            state.statusLoad = 'pending';
        });
        builder.addCase(getInfoUsers.fulfilled, (state, action)=>{
            const user =  action.payload
            for (const item in user){
                if(user[item]===null){
                    user[item]=''
                }
            }
            state.userInfo = user;
            state.statusLoad = 'fulfilled';
        });
        builder.addCase(getInfoUsers.rejected, (state, action)=>{
            state.error = action.payload.message;
            state.statusLoad = 'rejected';
        });
    }
})

export const {
    addOnlineAllUser,
    addOnlineUser,
    deleteOnlineUser,
    addChangeUser,
    changeSocketStatus,
    addAllUsers
} = usersSlice.actions;
export default usersSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchSpecialistList_searchSpecialistList__FLk5v {\n  padding: 20px 5px;\n  max-width: 1400px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n.SearchSpecialistList_searchSpecialistListTitle__2vaDa {\n  font-family: Museo-700, sans-serif;\n  font-size: 40px;\n  text-align: center;\n  color: #2F2E41;\n}\n.SearchSpecialistList_searchSpecialistListContainer__fmUkA {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 20px;\n}\n\n@media screen and (max-width: 1440px) {\n  .SearchSpecialistList_searchSpecialistList__FLk5v {\n    padding: 40px 20px;\n    max-width: 768px;\n  }\n  .SearchSpecialistList_searchSpecialistListContainer__fmUkA {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/SearchSpecialist/SearchSpecialistList/SearchSpecialistList.module.sass"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;AAEJ;AADE;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AAGJ;;AAFA;EACE;IACE,kBAAA;IACA,gBAAA;EAKF;EAJE;IACE,aAAA;IACA,qCAAA;IACA,SAAA;EAMJ;AACF","sourcesContent":[".searchSpecialistList\n  padding: 20px 5px\n  max-width: 1400px\n  margin: 0 auto\n  display: flex\n  flex-direction: column\n  gap: 40px\n  &Title\n    font-family: Museo-700,sans-serif\n    font-size: 40px\n    text-align: center\n    color: #2F2E41\n  &Container\n    display: grid\n    grid-template-columns: repeat(3, 1fr)\n    gap: 20px\n@media screen and (max-width: 1440px)\n  .searchSpecialistList\n    padding: 40px 20px\n    max-width: 768px\n    &Container\n      display: grid\n      grid-template-columns: repeat(2, 1fr)\n      gap: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchSpecialistList": "SearchSpecialistList_searchSpecialistList__FLk5v",
	"searchSpecialistListTitle": "SearchSpecialistList_searchSpecialistListTitle__2vaDa",
	"searchSpecialistListContainer": "SearchSpecialistList_searchSpecialistListContainer__fmUkA"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MessagesList_MessagesList__r5Gzq {\n  width: 100%;\n  padding: 0 60px;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}", "",{"version":3,"sources":["webpack://./src/components/Chat/MessagesList/MessagesList.module.sass"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AADF","sourcesContent":["@import '../../../sass/mixin'\n\n.MessagesList\n  width: 100%\n  padding: 0 60px\n  overflow-x: hidden\n  overflow-y: scroll\n  display: flex\n  flex-direction: column\n  align-items: flex-start\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessagesList": "MessagesList_MessagesList__r5Gzq"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import SettingUser from "../../components/SettingUser/SettingUser";
import {useSelector} from "react-redux";

const Settings = () => {
    const {user} = useSelector(state=>state.auth)
    return (
        <main>
            {user&&<SettingUser user={user}/>}
        </main>
    );
};

export default Settings;

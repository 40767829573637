// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_layout__bkcp2 {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-height: 100vh;\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,iBAAA;AADF","sourcesContent":["@import '../../sass/mixin'\n\n.layout\n  display: flex\n  flex-direction: column\n  justify-content: space-between\n  min-height: 100vh"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "Layout_layout__bkcp2"
};
export default ___CSS_LOADER_EXPORT___;

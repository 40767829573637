// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Chat_chat__55UXd {\n  width: 83.3333333333vw;\n  margin: 0 auto;\n}\n.Chat_chatTitle__nJT26 {\n  font-size: 2.0833333333vw;\n  font-family: Museo-900, sans-serif;\n  color: #5D3277;\n}\n.Chat_chatContainer__gdFO9 {\n  margin-top: 1.5625vw;\n  display: grid;\n  grid-template-columns: 30% 65%;\n  gap: 5%;\n}\n.Chat_chatContainerEmpty__J45Uk {\n  width: 100%;\n  height: 28.6458333333vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: Museo-900, sans-serif;\n  font-size: 2.34375vw;\n}", "",{"version":3,"sources":["webpack://./src/pages/Chat/Chat.module.sass"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,cAAA;AADF;AAEE;EACE,yBAAA;EACA,kCAAA;EACA,cAAA;AAAJ;AACE;EACE,oBAAA;EACA,aAAA;EACA,8BAAA;EACA,OAAA;AACJ;AAAI;EACE,WAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kCAAA;EACA,oBAAA;AAEN","sourcesContent":["@import ../../sass/mixin\n\n.chat\n  width: width1920(1600)\n  margin: 0 auto\n  &Title\n    font-size: width1920(40)\n    font-family: Museo-900,sans-serif\n    color: #5D3277\n  &Container\n    margin-top: width1920(30)\n    display: grid\n    grid-template-columns: 30% 65%\n    gap: 5%\n    &Empty\n      width: 100%\n      height: width1920(550)\n      display: flex\n      justify-content: center\n      align-items: center\n      font-family: Museo-900,sans-serif\n      font-size: width1920(45)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat": "Chat_chat__55UXd",
	"chatTitle": "Chat_chatTitle__nJT26",
	"chatContainer": "Chat_chatContainer__gdFO9",
	"chatContainerEmpty": "Chat_chatContainerEmpty__J45Uk"
};
export default ___CSS_LOADER_EXPORT___;

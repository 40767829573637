import React from 'react';
import style from "../AllUsers.module.sass";
import {motion} from "framer-motion";
import {changeActiveChats} from "../../../redux/reducers/Slices/chatsSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const AllUserItem = ({item, index}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {firstName, speciality, avatars, id} = item
    const specialityList = {
        accounting: 'бухгалтер',
        lawyer: 'Юрист',
        it: 'IT специалист'
    }
    const listVariants = {
        hidden: {
            opacity: 0.4,
            x: 1600
        },
        visible: i=>({
            opacity: 1,
            x: 0,
            transition: {
                delay: i*0.5,
            }
        })
    }
    const handleOpenChat = (idUser) => {
        dispatch(changeActiveChats(idUser))
        navigate('chat')
    }
    return (
        <motion.li
            variants={listVariants}
            initial={'hidden'}
            animate={'visible'}
            custom={index}
            className={style.listUserItem}
        >
            <div className={style.listUserItemBox}>
                <div className={style.listUserItemBoxImg}>
                    <img src={`${process.env.REACT_APP_SERVER_URL}/user/avatars/${avatars}`} alt=""/>
                </div>
                <div className={style.listUserItemBoxText}>
                    <span>{firstName}</span>
                    <span/>
                </div>
            </div>
            <button
                onClick={()=>handleOpenChat(id)}
            >{specialityList[speciality]}</button>
        </motion.li>
    );
};

export default AllUserItem;

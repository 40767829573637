import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const getAllChats = createAsyncThunk(
    "chats/getAllChats",
    async(_,{rejectWithValue, getState})=>{
        const {auth} = getState();
        const {user} = auth;
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+'/user-chats',{
                method: "GET",
                headers: {
                    'User-Role': user.role,
                    'UserID': user.id,
                    'Authorization': `Bearer ${user.token}`
                }
            })
            if(!response.ok){
                return new Error('Сервер недоступен')
            }
            return response.json();
        } catch (error){
            return rejectWithValue(error.message)
        }
    }
)

const chatsSlice = createSlice({
    name: 'chats',
    initialState: {
        activeChat: null,
        tempUserChat: null,
        allUsersChats: [],
        statusLoadChats: 'idle',
        statusUser: 'idle',
        error: null
    },
    reducers: {
      changeActiveChats(state, action){
          state.activeChat = action.payload
      },
      changeStatusMessages(state, action){

      },
      addMessageChat(state, action){
          const {message, companionInfo} = action.payload;
          const chat = state.allUsersChats.find(i=>i.chatId===message.chatId);
          if(!chat&&state.tempUserChat){
              const chat = {
                  companion: state.tempUserChat.companion,
                  messages: [message],
                  chatId: message.chatId
              }
              state.allUsersChats.push(chat)
              state.tempUserChat = null
          }else if(!chat&&!state.tempUserChat){
              const {chatId} = message
              const chat = {
                  companion: companionInfo,
                  messages: [message],
                  chatId: chatId
              }
              state.allUsersChats.push(chat)
          }else{
              chat.messages.push(message)
          }

      },
      tempUserChatAdd(state, action){
        state.tempUserChat = action.payload
      },
      addChatInAllUsersChats(state, action){
          state.allUsersChats.push(action.payload)
      },
      updateInfoUser(state, action){
          const {userId} = action.payload;
          const indexChat = state.allUsersChats.findIndex(i=>i.companion.userId===userId);
          if(indexChat){
              state.allUsersChats[indexChat].companion=action.payload
          }
      },
      updateStatusMessage(state, action){
          const {idChat, msgArr} = action.payload;
          const indexChat = state.allUsersChats.findIndex(i=>i.chatId===idChat)
          state.allUsersChats[indexChat].messages.forEach(msg=>{
              msgArr.forEach(i=>{
                  if(i===msg.id){
                      msg.status=true;
                  }
              })
          })
      }
    },
    extraReducers: (builder)=>{
        builder.addCase(getAllChats.pending,(state)=>{
            state.error = null;
            state.statusLoadChats = 'pending';
        });
        builder.addCase(getAllChats.fulfilled,(state, action)=>{
            state.allUsersChats = action.payload;
            state.statusLoadChats = 'fulfilled';
        });
        builder.addCase(getAllChats.rejected, (state, action)=>{
            state.error = action.payload.message;
            state.statusLoadChats = 'rejected';
        });
    }
})
export const {
    changeActiveChats,
    tempUserChatAdd,
    addChatInAllUsersChats,
    addMessageChat,
    updateStatusMessage,
    updateInfoUser,
} = chatsSlice.actions;
export default chatsSlice.reducer;

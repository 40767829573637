import React, {useCallback, useEffect} from 'react';
import styles from './ChatsList.module.sass';
import {useDispatch, useSelector} from "react-redux";
import ChatsItem from "../ChatsItem/ChatsItem";
import {v4 as uuidv4} from 'uuid';
import randomNumber from "../../../lib/randomNumber";
import {tempUserChatAdd} from "../../../redux/reducers/Slices/chatsSlice";

const ChatsList = () => {
    const dispatch = useDispatch();
    const { allUsersChats, tempUserChat, activeChat } = useSelector(state=>state.chats)
    const {allUsers}=useSelector(state=>state.users)
    //открыть чат если чата с таким юзером нет, тогда создать новый - временный
    const checkActiveChat = useCallback(() => {
        const chat = allUsersChats.find(item=>item.companion.userId===activeChat);
        const user = allUsers.find(item=>item.id===activeChat)
        if(!chat && activeChat&&user){
            const tempChat= {
                companion: {
                    userId: user.id,
                    userName: user.firstName,
                    userAvatars: user.avatars,
                    speciality: user.speciality,
                },
                chatId: randomNumber(100000,999999),
                messages: []
            }
            dispatch(tempUserChatAdd(tempChat))
        }
    }, [activeChat, allUsers, allUsersChats, dispatch])

    useEffect(()=>{
        checkActiveChat()
    }, [checkActiveChat])
    return (
        <ul className={styles.chatList}>
            {allUsersChats.map(item=><ChatsItem key={uuidv4()} item={item}/>)}
            {tempUserChat&&<ChatsItem item={tempUserChat}/>}
        </ul>
    );

};

export default ChatsList;

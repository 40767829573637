import React from 'react';
import styles from "../ChatsItem.module.sass";
import moment from "moment";

const ChatItemCountDate = ({newMessagesCount, lastMessage}) => {
    const dateNow = moment();
    const dateMessage =moment(lastMessage);
    const difDate = dateNow.diff(dateMessage, 'days');
    const timeMessage = () => {
        if(difDate===0){
            return dateMessage.format("h:mm")
        }else if(difDate>0&&difDate<7){
            return dateMessage.format("dd")
        }else{
            return dateMessage.format("D.MM.YYYY")
        }
    }
    return (
        <div className={styles.itemInfo}>
            <span className={styles.itemInfoTime}>{timeMessage()}</span>
            {newMessagesCount>=1&&<span className={styles.itemInfoNew}>{newMessagesCount}</span>}
        </div>
    );
};

export default ChatItemCountDate;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChatsList_chatList__N8U8f {\n  max-height: 31.25vw;\n  display: flex;\n  gap: 0.5208333333vw;\n  flex-direction: column;\n  overflow-x: hidden;\n  grid-column: 1/2;\n}", "",{"version":3,"sources":["webpack://./src/components/Chat/ChatsList/ChatsList.module.sass"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;AADF","sourcesContent":["@import \"../../../sass/mixin\"\n\n.chatList\n  max-height: width1920(600)\n  display: flex\n  gap: width1920(10)\n  flex-direction: column\n  overflow-x: hidden\n  grid-column: 1/2\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatList": "ChatsList_chatList__N8U8f"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import style from './Speciality.module.sass';
import {v4 as uuidv4} from 'uuid';
import {motion} from 'framer-motion'

const Speciality = ({data, changeStatus}) => {
    if(data){
        const {possibilities, title} = data
        const getArrayRandom = (arrayLength) => {
            const randomInteger = (min, max) => {
                // случайное число от min до (max+1)
                let rand = min + Math.random() * (max + 1 - min);
                return Math.floor(rand);
            }
            const arrayNumber = []
            let n = 0;
            while(n < arrayLength){
                let number = randomInteger(0, arrayLength-1)
                if(!arrayNumber.includes(number)){
                    arrayNumber.push(number)
                    n++
                } else number = randomInteger(0, arrayLength-1)
            }
            return arrayNumber
        }
        const arrayNumberRandom =  getArrayRandom(possibilities.length)
        const variants = {
            visible: (index)=>({
                opacity: 1,
                scale: 1,
                transition: {
                    delay:arrayNumberRandom[index]*0.35
                }
            }),
            hidden: {
                scale: 0.5,
                opacity: 0
            }
        }
        return (
            <main className={style.main}>
                <section className={style.mainSpeciality}>
                    {possibilities.map((item, index)=>{
                        return(
                            <motion.div
                                variants={variants}
                                initial={"hidden"}
                                animate={"visible"}
                                custom={index}
                                key={uuidv4()}
                                className={style.mainSpecialityItem}>
                                <div className={style.mainSpecialityItemIcon}>
                                    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.1111 21.9751C9.4619 21.9756 8.83013 21.765 8.31108 21.3751L8.27909 21.3511L1.4961 16.1631C0.861705 15.6767 0.446512 14.9582 0.341867 14.1657C0.237222 13.3731 0.451682 12.5715 0.938089 11.9371C1.4245 11.3027 2.143 10.8875 2.93553 10.7829C3.72805 10.6782 4.5297 10.8927 5.1641 11.3791L9.55708 14.7481L19.9381 1.20409C20.4243 0.570014 21.1424 0.15502 21.9345 0.0503771C22.7267 -0.0542658 23.5279 0.160014 24.1621 0.646095L24.0981 0.7351L24.1641 0.646095C24.7976 1.13292 25.2121 1.85125 25.3165 2.64338C25.421 3.43551 25.2068 4.2367 24.7211 4.87109L12.5101 20.7941C12.2273 21.1612 11.8636 21.4582 11.4474 21.662C11.0311 21.8658 10.5735 21.9709 10.1101 21.9691L10.1111 21.9751Z" fill="white"/>
                                    </svg>
                                </div>
                                <div className={style.mainSpecialityItemInfo}>
                                    <h3>{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>
                            </motion.div>
                        )
                    })}
                    <motion.div
                        initial={{x: 400}}
                        animate={{x: 0}}
                        className={style.mainSpecialityTitle}>
                        <h2>привет, я {title}</h2>
                        <p>для вас я могу</p>
                    </motion.div>
                </section>
                <section className={style.mainFooter}>
                    <div className={style.mainFooterContent}>
                        <h2>такого специалиста точно надо взять в свою команду !</h2>
                        <p>если вам нужен данный профессионал, мы сразу перебросим вас в личный кабинет, где вы сможете написать нужному специалисту задать ему все свои вопросы напрямую </p>
                        <button
                            onClick={changeStatus}
                        >мне это нужно!</button>
                    </div>
                </section>
            </main>
        );
    }
};

export default Speciality;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const changeStatusContent = createAsyncThunk(
    "auth/changeStatusContent",
    async ({id}, {getState, rejectWithValue}) => {
        const state = getState().auth
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+`/user/change-first-content`,{
                body: JSON.stringify({id}),
                method: "PATCH",
                headers: {
                    'Authorization': `Bearer ${state.user.token}`,
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            if(!data.ok){
                return rejectWithValue(data)
            }
            return data;
        }catch (error){
            return rejectWithValue(error.message)
        }
    }
)

export const login = createAsyncThunk(
    "auth/login",
    async({body}, {rejectWithValue})=>{
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+`/auth/login`,{
                body,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            if(!data.ok){
                return rejectWithValue(data)
            }
            return data;
        }catch (error){
            return rejectWithValue(error.message)
        }
    }
);
export const newConfirmArePassword = createAsyncThunk(
    "auth/newConfirmArePassword",
    async({body, url}, {rejectWithValue})=>{
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+`/auth/${url}`,{
                body,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            if(!data.ok){
                return rejectWithValue(data)
            }
            return data;
        }catch (error){
            return rejectWithValue(error.message)
        }
    }
);

export const getInfoForMe = createAsyncThunk(
    "auth/getInfoForMe",
    async(_, {rejectWithValue, getState})=>{
        const state = getState().auth;
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL+'/user/me',{
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${state.user.token}`,
                    'Content-Type': 'application/json'
                }
            })

            if(!response.ok){
                return rejectWithValue(response.data)
            }
            return  await response.json();
        }catch (error){
            return rejectWithValue(error.message)
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        statusLoad: 'idle',
        statusConfirmAndPassword: 'idle',
        statusChangeContent: 'idle',
        messageServer: '',
        error: null,
    },
    reducers: {
        resetStatusLoad(state){
            state.statusLoad = 'idle'
            state.statusConfirmAndPassword='idle'
            state.messageServer = ''
            state.error = null
        },
        logout(state){
            state.user = null
            state.statusLoad = 'idle'
            state.statusConfirmAndPassword='idle'
            state.messageServer = ''
            state.statusChangeContent= 'idle'
            state.error = null
            state.tokenStatus = false
        },
    },
    extraReducers: (builder)=>{
        builder.addCase(login.pending, (state)=>{
            state.error = null
            state.statusLoad = 'pending'
        });
        builder.addCase(login.fulfilled, (state,action)=>{
            state.statusLoad = 'fulfilled';
            state.user = action.payload.data;
        });
        builder.addCase(login.rejected, (state,action)=>{
            state.statusLoad = 'rejected';
            state.error = action.payload.message;
        });
        builder.addCase(newConfirmArePassword.pending, (state)=>{
            state.error = null
            state.statusConfirmAndPassword = 'pending'
        });
        builder.addCase(newConfirmArePassword.fulfilled, (state,action)=>{
            state.statusConfirmAndPassword = 'fulfilled';
            state.messageServer = action.payload.message;
        });
        builder.addCase(newConfirmArePassword.rejected, (state,action)=>{
            state.statusConfirmAndPassword = 'rejected';
            state.error = action.payload.message
        });
        builder.addCase(changeStatusContent.pending, (state)=>{
            state.error = null
            state.statusChangeContent = 'pending'
        });
        builder.addCase(changeStatusContent.fulfilled, (state)=>{
            state.statusChangeContent = 'fulfilled';
            state.user = {...state.user, firstConnect: true}

        });
        builder.addCase(changeStatusContent.rejected, (state,action)=>{
            state.statusChangeContent = 'rejected';
            state.error = action.payload.message
        });
        builder.addCase(getInfoForMe.pending, (state)=>{
            state.error = null
            state.statusLoad = 'pending'
        });
        builder.addCase(getInfoForMe.fulfilled, (state, action)=>{
            state.statusLoad = 'fulfilled';
            for(let key in action.payload){
                state.user = {...state.user,[key]: action.payload[key]}
            }
        });
        builder.addCase(getInfoForMe.rejected, (state,action)=>{
            state.statusLoad = 'rejected';
            state.error = action.payload.message
        });
    }
})
export const {
    resetStatusLoad,
    logout,
} = authSlice.actions;
export default authSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegPage_home__ScPrT {\n  overflow: hidden;\n  display: flex;\n  justify-content: space-between;\n  max-height: 100vh;\n  min-height: 100vh;\n}\n.RegPage_homeDecor__il4PI {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n.RegPage_homeDecorImgBox__8XKYC {\n  width: 47.2395833333vw;\n  height: auto;\n}\n.RegPage_homeDecorImgBox__8XKYC img {\n  width: 100%;\n  height: auto;\n}\n.RegPage_homeMain__aUf3Y {\n  max-width: 35.2604166667vw;\n  width: 100%;\n  border-radius: 2.0833333333vw 0 0 2.0833333333vw;\n  background: linear-gradient(180deg, rgba(196, 196, 196, 0.3) 0%, rgba(196, 196, 196, 0.015) 100%);\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/RegPage/RegPage.module.sass"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,iBAAA;AAAF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACJ;AAAI;EACE,sBAAA;EACA,YAAA;AAEN;AADM;EACE,WAAA;EACA,YAAA;AAGR;AAFE;EACE,0BAAA;EACA,WAAA;EACA,gDAAA;EACA,iGAAA;EACA,aAAA;EACA,mBAAA;AAIJ","sourcesContent":["@import '../../sass/mixin'\n.home\n  overflow: hidden\n  display: flex\n  justify-content: space-between\n  max-height: 100vh\n  min-height: 100vh\n  &Decor\n    display: flex\n    align-items: center\n    justify-content: center\n    width: 100%\n    &ImgBox\n      width: width1920(907)\n      height: auto\n      img\n        width: 100%\n        height: auto\n  &Main\n    max-width: width1920(677)\n    width: 100%\n    border-radius: width1920(40) 0 0 width1920(40)\n    background: linear-gradient(180deg, rgba(196, 196, 196, 0.3) 0%, rgba(196, 196, 196, 0.015) 100%)\n    display: flex\n    align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "RegPage_home__ScPrT",
	"homeDecor": "RegPage_homeDecor__il4PI",
	"homeDecorImgBox": "RegPage_homeDecorImgBox__8XKYC",
	"homeMain": "RegPage_homeMain__aUf3Y"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react"
import {useSelector} from "react-redux";
import style from './AdminHome.module.sass';

const AdminHome = () => {
    const {user} = useSelector(state => state.auth)
    return (
        <>
            <div className={style.adminHome}>
                <span>{user.firstName}</span>
                <h2>Добро пожаловать в "Твоя Система"</h2>
            </div>

        </>
    )
}

export default AdminHome;
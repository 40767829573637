import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import style from "../AdminSettingsUser/AdminSettingsUser.module.sass";
import download from "../../icons/downloading-file.png";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {getInfoForMe} from "../../redux/reducers/Slices/authSlice";

const SettingUser = ({user}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //контроль файла
    const [file, setFile] = useState(null);
    //контроль модального окна
    const [modal, setModal]= useState({
        status: false,
        message: null
    })
    //валидация формы
    const [validated, setValidated] = useState(false);
    //блокировка кнопки
    const [btnDisable, setBtnDisable] = useState(false);
    //временный user
    const [tempUser, setTempUser] = useState(null)
    //контроль файла
    const handleFileChange = (e)=>{
        if (e.target.files){
            setBtnDisable(true)
            setFile(e.target.files[0])
        }
    }
    //контроль формы
    const handleChangeForm = (e)=>{
        const {name, value} = e.target;
        setTempUser((prevState)=>({...prevState, [name]: value }))
        if(!btnDisable){
            setBtnDisable(true)
        }

    }
    //отправка формы
    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        setValidated(true);
        if(form.checkValidity()){
            const formData = new FormData();
            for (let key in tempUser){
                formData.set(key, tempUser[key])
            }
            if(file){
                formData.set('file', file, file.name)
            }
            await fetch(process.env.REACT_APP_SERVER_URL + `/user/update/${user.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
                method: "PATCH",
                body: formData
            })
                .then(r=>r.json())
                .then(res=>setModal({status: true, message: res.message}))

        }
    };
    //Закрытия окна и переход на главную
    const handleCloseModal = () => {
        dispatch(getInfoForMe())

        setModal({status: false, message: null})
        navigate('/home')
    }
    //сброс настроек
    const resetForm = ()=>{
        setTempUser(Object.assign(user))
        setBtnDisable(false)
        setFile(null)
    }

    useEffect( ()=>{
        setTempUser(Object.assign(user))
        setBtnDisable(false)
        setFile(null)
    }, [dispatch, user])
    return (
        <section>
            {tempUser&&<div className={style.settingsContainer}>
                <h1>Настройка пользователя</h1>
                <div className={style.settingsContainerCard}>
                    <div className={style.settingsContainerCardPhoto}>
                        <div className={style.settingsContainerCardPhotoImage}>
                            {
                                !file&&<img src={process.env.REACT_APP_SERVER_URL+`/user/avatars/${tempUser.avatars}`} alt=""/>
                            }
                            {
                                file&&<img src={`${URL.createObjectURL(file)}`}
                                alt=""/>
                            }
                        </div>
                        <label className={style.settingsContainerCardPhotoBtn}>
                            <input
                                type="file"
                                onChange={handleFileChange}
                            />
                            <div className={style.settingsContainerCardPhotoBtnIcon}>
                                <img src={download} alt="download"/>
                            </div>
                        </label>
                    </div>
                    <div className={style.settingsContainerCardInfo}>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            className={style.settingsContainerCardInfoForm}
                        >
                            <Form.Label>
                                <span>Имя пользователя</span>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Имя"
                                    aria-describedby="firstName"
                                    name={'firstName'}
                                    value={tempUser.firstName}
                                    onChange={handleChangeForm}
                                />
                                <Form.Text muted>
                                    имя отображаемое на главной странице и в чате
                                </Form.Text>
                            </Form.Label>
                            <Form.Label >
                                <span>Логин</span>
                                <Form.Control
                                    required
                                    type="text"
                                    id="name"
                                    placeholder="Логин"
                                    aria-describedby="userName"
                                    name={'userName'}
                                    value={tempUser.userName}
                                    onChange={handleChangeForm}
                                />
                                <Form.Text id="userName">
                                    логин для авторизации
                                </Form.Text>
                            </Form.Label>
                            <Form.Label >
                                <span>Email</span>
                                <Form.Control
                                    value={tempUser.email}
                                    required
                                    type="email"
                                    id="email"
                                    aria-describedby="email"
                                    name={'email'}
                                    onChange={handleChangeForm}
                                />
                                <Form.Text id="email" muted>
                                    адрес электронной почты используемый для авторизации
                                </Form.Text>
                            </Form.Label>
                            <div className={style.settingsContainerCardInfoFormBtns}>
                                <Button
                                    disabled={!btnDisable}
                                    variant={'success'}
                                    type={"submit"}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    onClick={resetForm}
                                    disabled={!btnDisable}
                                    variant={'primary'}
                                >
                                    Отмена
                                </Button>
                                <Button
                                    variant={'secondary'}
                                    onClick={()=>navigate('/home')}
                                >
                                    На главную
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>}
            <Modal
                show={modal.status}
                onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Сохранение изменений</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleCloseModal}
                        variant="primary" >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default SettingUser;

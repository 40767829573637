import './App.sass';
import {Route, Routes} from "react-router-dom";
import RegPage from "./pages/RegPage/RegPage";
import AuthorizationPage from "./pages/AuthorizationPage/AuthorizationPage";
import SuccessEmailPage from "./pages/SuccessEmailPage/SuccessEmailPage";
import Chat from "./pages/Chat/Chat";
import ErrPage404 from './pages/Error/404';
import NotConfirmEmail from "./pages/NotConfirmEmail/NotConfirmEmail";
import ProtectedRouter from "./components/ProtectedRouter/ProtectedRouter";
import Home from './pages/Home/Home'
import {useDispatch, useSelector} from 'react-redux';
import NewPassword from './pages/New-Password/New-Password';
import Start from "./pages/Start/Start";
import Layout from "./components/Layout/Layout";
import {useCallback, useEffect, useState} from "react";
import {logout} from "./redux/reducers/Slices/authSlice";
import AdminHome from "./pages/AdminHome/AdminHome";
import Settings from "./pages/Settings/Settings";
import SuperAdmin from './components/SuperAdmin/SuperAdmin'
import AdminSettingsUser from "./components/AdminSettingsUser/AdminSettingsUser";
import {useSocket} from "./hooks/useSocket";
import {getAllChats} from "./redux/reducers/Slices/chatsSlice";
import SearchSpecialistList from "./components/SearchSpecialist/SearchSpecialistList/SearchSpecialistList";

function App() {
    const { user } = useSelector(state=>state.auth);
    const dispatch = useDispatch();
    const [tokenStatus, setTokenStatus]= useState(false)
    const socket = useSocket();
    const {sendMessage, updateMessages,disconnectUser} = socket;

  //запрос на проверку актуальности токена
    const checkToken = useCallback(async ()=>{
        if(!tokenStatus){
            try{
                const resp = await fetch(process.env.REACT_APP_SERVER_URL+'/auth/check',{
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`,
                        'Accept': 'application/json'
                    }
                })
                if(!resp.ok){
                    dispatch(logout())
                    setTokenStatus(false)
                }
            } catch(err){
                console.log('Сервер не доступен')
                dispatch(logout())
                setTokenStatus(false)
            }
        }
      },[dispatch, tokenStatus, user]);

    useEffect(()=>{
        const check = ()=>{
            if(!!user){
                void checkToken()
            }
        }
        check()
        dispatch(getAllChats())
    },[dispatch, checkToken, user])
    return (
        <div className="App">
            <Routes>
                <Route index element={<Start/>}/>
                <Route path={'registration'} element={<RegPage/>}/>
                <Route path={'authorization'} element={<AuthorizationPage/>}/>
                <Route path={'success-email'} element={<SuccessEmailPage/>}/>
                <Route path={'error404'} element={<ErrPage404/>}/>
                <Route path={'expired-token'} element={<NotConfirmEmail/>}/>
                <Route path={'new-password'} element={<NewPassword/>}/>
                <Route element={<ProtectedRouter isAllowed={!!user}/>}>
                    <Route path='/home' element={<Layout disconnect={disconnectUser}/>}>
                        {user?.role==='user'&&<Route index element={<Home/>}/>}
                        {user?.role==='admin'&&<Route index element={<AdminHome/>}/>}
                        {user?.role==='super_admin'&&<Route index element={<SuperAdmin/>}/>}
                        {
                            user?.role!=='super_admin'&&
                            <Route
                                path={'chat'}
                                element={
                                    <Chat sendMessage={sendMessage} updateMessages={updateMessages}/>
                                }
                            />
                        }
                        {
                            user?.role!=='super_admin'&& user?.role!=='admin'&&
                            <Route
                                path={'all-speciality'}
                                element={
                                    <SearchSpecialistList/>
                                }
                            />
                        }
                        {
                            user?.role!=='super_admin'&& user?.role!=='admin'&&
                            <Route
                                path={'all-speciality/:id'}
                                element={
                                    <h1>Тут будет подробное описание специалиста</h1>
                                }
                            />
                        }
                        <Route path={'settings'} element={<Settings/>}/>
                        {user?.role==='super_admin'&&<Route path={'setting-user/:id'} element={<AdminSettingsUser/>}/>}
                    </Route>
                </Route>
                <Route path={'*'} element={<ErrPage404/>}/>
            </Routes>
        </div>
    );
}

export default App;

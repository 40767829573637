import React from 'react';
import {useSelector} from "react-redux";
import AllUsers from "../AllUsers/AllUsers";
import style from './HomeUser.module.sass';
import monitor from '../../images/monitor.png';
import girlHome from '../../images/homeGirle.png';
import {Link} from "react-router-dom";
const HomeUser = () => {
    const {user}=useSelector(state=>state.auth)
    return (
        <main className={style.main}>
            <div className={style.mainContainer}>
                <section className={style.mainContainerHello}>
                    <div className={style.mainContainerHelloTitle}>
                        <h1>здравствуйте, {user.userName}</h1>
                        <span>сейчас к вашим услугам</span>
                    </div>
                    <AllUsers/>
                </section>
                <section className={style.mainContainerContent}>
                    <div className={style.mainContainerContentTitle}>
                        <h2>если вам не хватает помощников, вы всегда можете найти их у нас</h2>
                        <Link to={'/home/all-speciality'}>найти помощника</Link>
                    </div>
                    <div className={style.mainContainerContentMonitor}>
                        <img src={monitor} alt=""/>
                    </div>
                    <div className={style.mainContainerContentHowWork}>
                        <h2>как это работает</h2>
                        <div className={style.mainContainerContentHowWorkBox}>
                            <div className={style.mainContainerContentHowWorkBoxImg}>
                                <img src={girlHome} alt=""/>
                            </div>
                            <div className={style.mainContainerContentHowWorkBoxText}>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas odio elementum non eget enim ac hendrerit blandit elit. Lobortis montes, elementum quam vitae sapien interdum faucibus mi.
                                </p>
                                <p>
                                    Proin ut elementum non diam viverra sagittis, iaculis. Suspendisse ullamcorper nisl nunc ante. Placerat pellentesque porttitor massa dignissim netus non.
                                </p>
                                <p>
                                    Tristique integer leo felis interdum quis purus justo. Quam volutpat augue lectus purus platea. Amet et purus lacinia eget dolor tempor dictumst rhoncus fringilla. Odio amet elementum phasellus neque.
                                </p>
                                <p>
                                    Nibh laoreet sit suspendisse massa libero, cras duis. Maecenas aliquam diam adipiscing id etiam.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <span className={style.mainDecor}/>
        </main>
    );
};

export default HomeUser;

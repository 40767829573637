import React, {useState} from 'react';
import {Link} from "react-router-dom";
import style from './Registration.module.sass';
import {useDispatch, useSelector} from "react-redux";
import {inputControl, register,} from "../../redux/reducers/Slices/formAuthorizationSlice";
import {motion} from "framer-motion";
import clsx from "clsx";

const Registration = () => {
    const [statusSelect, setStatusSelect] = useState(false)
    const [variantRegistration, setVariantRegistration] = useState(0)
    const specialityList = {
        '': 'Выберете специальность',
        'lawyer': 'Юрист',
        'it': 'IT  специалист',
        'accounting': 'Бухгалтер'
    }
    const dispatch = useDispatch()
    const {
        userName,
        password,
        email,
        confirmation,
        role,
        speciality,
        secretKey,
        permission
    } = useSelector(state => state.formAuthorization)

    const [passwordValid, setPasswordValid] = useState({
        password: true,
        permission: true
    })
    const handleChangeSelect = (e)=>{
        const valueDataName = e.currentTarget.getAttribute('dataname');
        dispatch(inputControl({name: "speciality", value: valueDataName}));
        setStatusSelect(false)
    }
    const handleChangeInput = (e)=>{
        const {name, value} = e.target;
        switch (name){
            case ('permission'):
                dispatch(inputControl({name, value: e.target.checked}));
                break
            case ('role'):
                dispatch(inputControl({name, value: e.target.checked}));
                break
            default:
                dispatch(inputControl({name, value}));
        }
    }
    const handleBackForm = (e) => {
        e.preventDefault()
        setVariantRegistration(0)
    }
    const submitForm = (e)=>{
        e.preventDefault()
        const validPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,12}/g.test(password);
        setPasswordValid(prevState => ({...prevState, password: validPassword}))
        const checkPermission = password===confirmation;
        setPasswordValid(prevState => ({...prevState, permission: checkPermission}))
        if (!validPassword||!checkPermission){
            return;
        }
        if(role&&variantRegistration===0){
            setVariantRegistration(1)
            return
        }
        const data = {
            userName,
            password,
            role,
            speciality,
            secretKey,
            email
        };
        localStorage.clear();
        localStorage.login = JSON.stringify({email, password});
        validPassword&&checkPermission&&dispatch(register({body:JSON.stringify(data)}))
    }
    return (
        <div className={style.registration}>
            {variantRegistration===0&&
                <motion.div
                    initial={{opacity: 0, scale: 0}}
                    animate={{opacity: 1, scale: 1}}
                    exit={{opacity: 0, scale: 0, y: 100}}
                >
                    <h2 className={style.registrationTitle}>регистрация</h2>
                    <span className={style.registrationLogin}>
                            уже есть аккаунт?<Link to={'/authorization'}>войти</Link>
                        </span>
                    <form
                        onSubmit={submitForm}
                        className={style.registrationForm}>
                        <input
                            value={userName}
                            onChange={handleChangeInput}
                            required
                            className={style.registrationFormInput}
                            type="text"
                            name={'userName'}
                            placeholder={'ваше имя'}/>
                        <input
                            value={email}
                            onChange={handleChangeInput}
                            required
                            className={style.registrationFormInput}
                            type="email"
                            name={'email'}
                            placeholder={'ваш email'}/>
                        <label className={style.registrationFormLabel}>
                            <motion.input
                                value={password}
                                onChange={handleChangeInput}
                                minLength={2}
                                required
                                className={style.registrationFormInput}
                                type="password"
                                name={'password'}
                                placeholder={'пароль'}/>
                            <motion.span
                                initial={{opacity:0}}
                                animate={passwordValid.password?{opacity:0}:{opacity:1}}
                                className={style.registrationFormLabelError}>пароль должен содержать минимум одну строчную и одну заглавную буквы и цифру, быть не короче 8 символов</motion.span>
                        </label>
                        <label className={style.registrationFormLabel}>
                            <motion.input
                                value={confirmation}
                                onChange={handleChangeInput}
                                required
                                minLength={2}
                                className={style.registrationFormInput}
                                type="password"
                                name={'confirmation'}
                                placeholder={'повторите пароль'}/>
                            <motion.span
                                initial={{opacity:0}}
                                animate={passwordValid.permission?{opacity:0}:{opacity:1}}
                                className={style.registrationFormLabelError}>пароли должны совпадать</motion.span>
                        </label>
                        <label className={style.registrationFormGroup}>
                            <input
                                required
                                onChange={handleChangeInput}
                                checked={permission}
                                className={style.registrationFormGroupCheckBox}
                                type="checkbox"
                                name={'permission'}/>
                            <span className={style.registrationFormGroupText}>Согласие на обработку персональных данных</span>
                        </label>
                        <label className={style.registrationFormGroup}>
                            <input
                                onChange={handleChangeInput}
                                checked={role}
                                className={style.registrationFormGroupCheckBox}
                                type="checkbox"
                                name={'role'}/>
                            <span className={style.registrationFormGroupText}>Я специалист</span>
                        </label>
                        <button className={style.registrationFormBtn}>Зарегистрироваться</button>
                    </form>
                </motion.div>
            }
            {variantRegistration===1&&
                <motion.div
                    initial={{opacity: 0, scale: 0}}
                    animate={{opacity: 1, scale: 1}}
                    exit={{opacity: 0, scale: 0}}
                >
                    <h2 className={style.registrationTitle}>Выберете свою специальность</h2>
                    <form
                        onSubmit={submitForm}
                        className={style.registrationForm}>
                        <div className={style.registrationFormBox}>
                            <span>Ваша специальность</span>
                            <div className={clsx([style.registrationFormInput, style.registrationFormSelect])}>
                                <span
                                    onClick={()=>setStatusSelect(!statusSelect)}
                                >{specialityList[speciality]}</span>
                                <motion.ul
                                    initial={{height: 0, opacity: 0, marginTop: 0}}
                                    animate={statusSelect?{height: "auto", opacity: 1, marginTop: '10px'}:{height: 0, opacity: 0, marginTop: 0}}
                                    className={style.registrationFormSelectOption}>
                                    <li
                                        dataname={'lawyer'}
                                        onClick={handleChangeSelect}
                                    >
                                        Юрист
                                    </li>
                                    <li
                                        dataname={'it'}
                                        onClick={handleChangeSelect}
                                    >
                                        IT специалист
                                    </li>
                                    <li
                                        dataname={'accounting'}
                                        onClick={handleChangeSelect}
                                    >
                                        Бухгалтер
                                    </li>
                                </motion.ul>

                            </div>
                            <label className={style.registrationFormLabel}>
                                <motion.input
                                    value={secretKey}
                                    onChange={handleChangeInput}
                                    minLength={2}
                                    required
                                    className={style.registrationFormInput}
                                    type="text"
                                    name={'secretKey'}
                                    placeholder={'Секретный ключ'}/>
                            </label>
                        </div>
                        <button
                            onClick={submitForm}
                            className={style.registrationFormBtn}
                        >
                            Продолжить
                        </button>
                        <button
                            style={{background: "white", color: "#61357B"}}
                            onClick={handleBackForm}
                            className={style.registrationFormBtn}
                        >
                            Назад
                        </button>
                    </form>
                </motion.div>
            }
        </div>
    );
};

export default Registration;

import React from 'react';
import style from './SearchSpecialistItem.module.sass';
import {Link} from "react-router-dom";

const SearchSpecialistItem = ({user}) => {
    const special = {
        it: 'IT специалист',
        lawyer: 'Юрист',
        accounting: 'Бухгалтер'
    }
    const {firstName, id, description, avatars, speciality} = user
    return (
        <li className={style.searchSpecialistItem}>
            <div className={style.searchSpecialistItemAvatar}>
                <img src={`${process.env.REACT_APP_SERVER_URL}/user/avatars/${avatars}`} alt=""/>
            </div>
            <h2 className={style.searchSpecialistItemTitle}>{firstName}</h2>
            <h2 className={style.searchSpecialistItemSpec}>{special[speciality]}</h2>
            <p className={style.searchSpecialistItemDesc}>{description.length>150?`${description.substring(0, 150)}...`:description}</p>
            <Link className={style.searchSpecialistItemLink} to={`/home/all-speciality/${id}`}>Подробнее</Link>
        </li>
    );
};

export default SearchSpecialistItem;
import React, { useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addChangeUser, getInfoUsers} from "../../redux/reducers/Slices/usersSlice";
import style from './AdminSettingsUser.module.sass';
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import download from '../../icons/downloading-file.png'
import Modal from "react-bootstrap/Modal";

const AdminSettingsUser = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const {userInfo, statusLoad} = useSelector(state=>state.users)
    const {user} = useSelector(state=>state.auth)
    //валидация формы
    const [validated, setValidated] = useState(false);
    //контроль файла
    const [file, setFile] = useState(null);
    //контроль модального окна
    const [modal, setModal]= useState({
        status: false,
        message: null
    })
    //отправка формы
    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        setValidated(true);
        if(form.checkValidity()){
            const formData = new FormData();
            for (let key in userInfo){
                formData.set(key, userInfo[key])
            }
            if(file){
                formData.set('file', file, file.name)
            }
            await fetch(process.env.REACT_APP_SERVER_URL + `/user/update/${params.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
                method: "PATCH",
                body: formData
            }).then(r=>r.json()).then(res=>setModal({status: true, message: res.message}))

        }
    };
    //контроль файла
    const handleFileChange = (e)=>{
        if (e.target.files){
            setFile(e.target.files[0])
            setBtnDisable(true)
        }
    }
    //контроль формы
    const handleChangeForm = (e)=>{
        const {name, value, checked, type} = e.target;
        if(type==='checkbox'){
            dispatch(addChangeUser({name, value:checked}))
        }else if (name==='role' && value==='user'){
            dispatch(addChangeUser({name, value}))
            dispatch(addChangeUser({name:'speciality', value:'user'}))
        }
        else {
            dispatch(addChangeUser({name, value}))
        }
        setBtnDisable(true)
    }
    //сброс настроек на дефолтные
    const [defaultSettings, setDefaultSettings] = useState(false)
    //блокировка кнопки
    const [btnDisable, setBtnDisable] = useState(false)
    //Закрытия окна и переход на главную
    const handleCloseModal = () => {
        setModal({status: false, message: null})
        navigate('/home')
    }

    useEffect( ()=>{
        dispatch(getInfoUsers({id: params.id}))
        setDefaultSettings(false)
        setBtnDisable(false)
        setFile(null)
    }, [dispatch, params, defaultSettings])
    return (
            <section className={style.settings}>
                {statusLoad==='pending'&&
                    <div className={style.settingsPreloader}>
                        Загрузка...
                    </div>
                }
                {statusLoad==='fulfilled'&&userInfo&&
                    <div className={style.settingsContainer}>
                        <h1>Настройка пользователя</h1>
                        <div className={style.settingsContainerCard}>
                            <div className={style.settingsContainerCardPhoto}>
                                <div className={style.settingsContainerCardPhotoImage}>
                                    {
                                        !file&&userInfo&&
                                        <img src={process.env.REACT_APP_SERVER_URL+`/user/avatars/${userInfo.avatars}`} alt=""/>
                                    }
                                    {file&&<img src={`${URL.createObjectURL(file)}`}
                                          alt=""/>}
                                </div>
                                <label className={style.settingsContainerCardPhotoBtn}>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <div className={style.settingsContainerCardPhotoBtnIcon}>
                                        <img src={download} alt="download"/>
                                    </div>
                                </label>
                            </div>
                            <div className={style.settingsContainerCardInfo}>
                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    className={style.settingsContainerCardInfoForm}
                                >
                                    <Form.Label>
                                        <span>Имя пользователя</span>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Имя"
                                            aria-describedby="firstName"
                                            name={'firstName'}
                                            value={userInfo.firstName}
                                            onChange={handleChangeForm}
                                        />
                                        <Form.Text muted>
                                            имя отображаемое на главной странице и в чате
                                        </Form.Text>
                                    </Form.Label>
                                    <Form.Label >
                                            <span>Логин</span>
                                            <Form.Control
                                                required
                                                type="text"
                                                id="name"
                                                placeholder="Логин"
                                                aria-describedby="userName"
                                                name={'userName'}
                                                value={userInfo.userName}
                                                onChange={handleChangeForm}
                                            />
                                            <Form.Text id="userName">
                                                логин для авторизации
                                            </Form.Text>
                                        </Form.Label>
                                    <Form.Label >
                                            <span>Email</span>
                                            <Form.Control
                                                value={userInfo.email}
                                                required
                                                type="email"
                                                id="email"
                                                aria-describedby="email"
                                                name={'email'}
                                                onChange={handleChangeForm}
                                            />
                                            <Form.Text id="email" muted>
                                                адрес электронной почты используемый для авторизации
                                            </Form.Text>
                                        </Form.Label>
                                    <Form.Label >
                                            <span>Секретный ключ для доступа к чату</span>
                                            <Form.Control
                                                type="text"
                                                id="secretIdChat"
                                                aria-describedby="secretIdChat"
                                                name={'secretIdChat'}
                                                value={userInfo.secretIdChat}
                                                onChange={handleChangeForm}
                                            />
                                            <Form.Text id="secretIdChat" muted>
                                                ключ доступа к чату
                                            </Form.Text>
                                        </Form.Label>
                                    {userInfo.role==='admin'&&<Form.Label >
                                        <span>Описание</span>
                                        <Form.Control
                                            type="text"
                                            id="description"
                                            aria-describedby="description"
                                            name={'description'}
                                            value={userInfo.description}
                                            onChange={handleChangeForm}
                                            as={'textarea'}
                                            rows={5}
                                        />
                                        <Form.Text id="secretIdChat" muted>
                                            Описание специалиста
                                        </Form.Text>
                                    </Form.Label>}
                                    <Form.Label >
                                        <span>Роль</span>
                                        <Form.Select
                                            id={'role'}
                                            defaultValue={userInfo.role}
                                            onChange={handleChangeForm}
                                            name={'role'}
                                        >
                                            <option value="user">Пользователь</option>
                                            <option value="admin">Администратор</option>
                                        </Form.Select>
                                    </Form.Label>
                                    <Form.Label>
                                        <span>Специальность</span>
                                        <Form.Select
                                            id={'speciality'}
                                            defaultValue={userInfo.role==='user'?'user':userInfo.speciality}
                                            onChange={handleChangeForm}
                                            name={'speciality'}
                                        >
                                            {
                                                userInfo.role==='admin'&&
                                                <option value="lawyer">Юрист</option>
                                            }
                                            {
                                                userInfo.role==='admin'&&
                                                <option value="accounting">Бухгалтер</option>
                                            }
                                            {
                                                userInfo.role==='admin'&&
                                                <option value="it">IT специалист</option>
                                            }
                                            {
                                                userInfo.role==='user'&&
                                                <option value="user">Пользователь</option>
                                            }
                                        </Form.Select>
                                    </Form.Label>
                                    <div className={style.settingsContainerCardInfoFormCheckBox}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Первое посещение"
                                            onChange={handleChangeForm}
                                            name={'firstConnect'}
                                            value={userInfo.firstConnect}
                                            checked={userInfo.firstConnect}
                                        />
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Подтверждение почты"
                                            onChange={handleChangeForm}
                                            name={'isActive'}
                                            value={userInfo.isActive}
                                            checked={userInfo.isActive}
                                        />
                                        {userInfo.role!=='user'&&<Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Отображать на главной"
                                            onChange={handleChangeForm}
                                            name={'popular'}
                                            value={userInfo.popular}
                                            checked={userInfo.popular}
                                        />}
                                    </div>
                                    <div className={style.settingsContainerCardInfoFormBtns}>
                                        <Button
                                            disabled={!btnDisable}
                                            variant={'success'}
                                            type={"submit"}
                                        >
                                            Сохранить
                                        </Button>
                                        <Button
                                            disabled={!btnDisable}
                                            onClick={()=>setDefaultSettings(true)}
                                            variant={'primary'}
                                        >
                                            Отмена
                                        </Button>
                                        <Button
                                            variant={'secondary'}
                                            onClick={()=>navigate('/home')}
                                        >
                                            Вернуться к списку
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                }
                <Modal
                    show={modal.status}
                    onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Сохранение изменений</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={handleCloseModal}
                            variant="primary" >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
    );
};

export default AdminSettingsUser;

import React from 'react';

const ChatItemMessages = ({messages}) => {
    return(
        <p>{messages[messages.length-1].content.length>40?
            messages[messages.length-1].content.slice(0, 40)+'...':
            messages[messages.length-1].content}</p>
        );
};

export default ChatItemMessages;

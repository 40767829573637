import React, { useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import AllUserList from "./AllUserList/AllUserList";
import {addAllUsers} from "../../redux/reducers/Slices/usersSlice";



const AllUsers = () => {
    const dispatch = useDispatch()
    const {user} = useSelector(state=>state.auth);
    const [usersData, setUsersData] = useState(null)
    useEffect(()=>{
        const users = async ()=>{
            const data = await fetch(process.env.REACT_APP_SERVER_URL+'/user/all-users',{
                method: "GET",
                headers: {
                    'User-Role': user.role,
                    'UserID': user.id,
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(r=>r.json())
            setUsersData(data.data)
            dispatch(addAllUsers(data.data))
        }
        users().then()
    },[user, dispatch])
    return (
        <>
            {usersData&&<AllUserList list={usersData}/> }
        </>
    )

};

export default AllUsers;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileHeader_HeaderProfile__XFM\\+O {\n  font-size: 1.1458333333vw;\n  gap: 1.0416666667vw;\n  display: flex;\n  align-items: center;\n}\n.ProfileHeader_HeaderProfileImg__X6g-4 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 2.6041666667vw;\n  height: 2.6041666667vw;\n}\n.ProfileHeader_HeaderProfileImg__X6g-4 img {\n  border-radius: 2.6041666667vw;\n  width: 100%;\n  height: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/ProfileHeader/ProfileHeader.module.sass"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AADF;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,sBAAA;AAAJ;AACI;EACE,6BAAA;EACA,WAAA;EACA,YAAA;AACN","sourcesContent":["@import '../../sass/mixin'\n\n.HeaderProfile\n  font-size: width1920(22)\n  gap: width1920(20)\n  display: flex\n  align-items: center\n  &Img\n    display: flex\n    justify-content: center\n    align-items: center\n    width: width1920(50)\n    height: width1920(50)\n    img\n      border-radius: width1920(50)\n      width: 100%\n      height: auto"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderProfile": "ProfileHeader_HeaderProfile__XFM+O",
	"HeaderProfileImg": "ProfileHeader_HeaderProfileImg__X6g-4"
};
export default ___CSS_LOADER_EXPORT___;
